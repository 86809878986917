import {action, computed, observable, transaction} from "mobx";
import {accountsAPI} from "api";
import {appUrlsService, socketService} from "services";
import {currentUserStore, editAccountStore, navigationStore} from "stores";
import BaseAccountStore from "./base/BaseAccountStore";
import {AccountOnboardingStatus, CollectionSteps} from "common/enums";
import ICompanyLegalInfo from "../interfaces/ICompanyLegalInfo";

export default class EditAccountStore extends BaseAccountStore {
    @observable public companyLegalInfoItem: ICompanyLegalInfo;
    @observable public isLoadingCompanyLegalInfoItem: boolean;

    @action
    public reset() {
        super.reset();
    }

    @action
    public getItem(id: string) {
        this.isLoading = true;

        accountsAPI.getOne(id)
            .then((item) => this.setItem(item.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    public setItem(item) {
        transaction(() => {
            this.item = item;
            this.selectedActivity = this.item.activities.length > 0 ? this.item.activities[0] : null;
            this.loadError = false;
            this.isLoading = false;
        });
    }

    @action
    public submit() {
        if (!this.validateNoDuplicatedActivityKeyValue()) {
            return;
        }
        accountsAPI.update(this.item._id, this.item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action
    public onboardingSubmit() {
        accountsAPI.updateCompanyLegalInfo(this.companyLegalInfoItem._id, this.companyLegalInfoItem)
            .then(() => this.onOnboardingSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    private onOnboardingSubmitSuccess() {
        this.updateOnboardingStatus(AccountOnboardingStatus.SUBMIT_SITELIST, appUrlsService.onboardingSubmitSiteList());
    }

    public onSkip() {
        navigationStore.push(appUrlsService.onboardingSubmitSiteList());
    }

    @action
    public delete() {
        accountsAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.accounts()))
            .catch((err) => this.onSubmitFailed(err));
    }

    public updateOnboardingStatus(status: AccountOnboardingStatus, path?: string) {
        accountsAPI.updateOnboardingStatus(status).then(() => path ? navigationStore.push(path) : window.location.reload());
        currentUserStore.updateCurrentAccountOnboardingStatus(status);
    }

    public async connectToAccountRoom() {
        socketService.connectToAccountRoom(this.item._id);
        socketService.startListenToMlkProCollectionUpdates((data) => this.onMlkProCollectionUpdates(data));
    }

    public async disconnectFromAccountRoom() {
        socketService.disconnectFromAccountRoom(this.item._id);
        socketService.stopListenToMlkProCollectionUpdates((data) => this.onMlkProCollectionUpdates(data));
    }

    public async getCollectionData(collectionId: string) {
        accountsAPI.getCollectionData(collectionId)
            .then((res) => this.onMlkProCollectionUpdates(res.data));
    }

    @action
    private onMlkProCollectionUpdates = (data: any) => {
        this.mlkProCollection = data;
        const activity = this.item.activities.find((row) => row._id === data.publisherUiActivityId);
        if (!activity.more) {
            activity.more = {};
        }
        (activity as any).more.collectionId = data._id;
    };

    @computed
    public get currentCollectionStepIndex() {
        if (this.mlkProCollection && this.mlkProCollection.collectionStep) {
            return CollectionSteps.findIndex((stepRow) => stepRow === this.mlkProCollection.collectionStep);
        } else {
            return -1;
        }
    };

    @action
    public resetCompanyLegalInfoItem() {
        transaction(() => {
            this.companyLegalInfoItem = {
                _id: "",
                contactEmail: "",
                contactPhone: "",
                contactAddress: "",
                gamNetworkCode: "",
                gamAdministratorEmail: "",
                contactFullName: ""
            };
        });
        this.isLoadingCompanyLegalInfoItem = false;
    }

    @action
    public getCompanyLegalInfoItem(id: string) {
        this.isLoadingCompanyLegalInfoItem = true;

        accountsAPI.getCompanyLegalInfo(id)
            .then((item) => this.setCompanyLegalInfoItem(item.data))
            .catch((err) => this.onLoadCompanyLegalInfoItemFailed(err));
    }

    @action
    public setCompanyLegalInfoItem(item) {
        transaction(() => {
            this.companyLegalInfoItem = item;
            this.loadError = false;
            this.isLoadingCompanyLegalInfoItem = false;
        });
    }

    @action public onLoadCompanyLegalInfoItemFailed(err: any) {
        transaction(() => {
            this.isLoadingCompanyLegalInfoItem = false;
            this.loadError = true;
        });

        this.notificationsStore.pushErrorNotification({
            title: "Operation Error",
            text: `Failed to load Company and Legal information`,
            timeout: 3000
        });
    }

    @action public updateCompanyLegalInfoParam = (key: string, value: any) => {
        this.companyLegalInfoItem[key] = value;
    }

}
