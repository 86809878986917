import * as React from 'react';
import Switch from 'react-router-dom/Switch';
import Redirect from 'react-router-dom/Redirect';
import {
    Dashboard,
    Reporting,
    Account,
    Accounts,
    Profile,
    ScheduleReports,
    ScheduleReport,
    OnboardingIntro,
    OnboardingSubmitSiteList,
    OnboardingDownloadAdsTxt,
    OnboardingDemoWidget,
    OnboardingCompanyLegal,
    DemoWidget,
    SubmitSite,
    SiteList,
    DownloadAdsTxt,
    Widgets,
    Site,
    AccountManagement,
    ScheduleReportsAdminLogs,
    ScheduleReportsAdminAll,
    TestPages,
    ReportingLogs,
    MediaCatalog,
    UpdateMetrics,
    GlobalPresetsReports,
    GlobalPresetReport,
    UpdateDimensions,
    UpdatePointOfContact,
    NotificationCenter,
    NotificationSettings,
    Connections,
    Connection,
    DemandPartners,
    Videos,
    DomainsLists,
    DomainList,
    CountryLists,
    CountryList
} from 'containers';
import {AuthRoute, AppHeader, NoPermissionRoute, OnboardingPendingMessage} from 'components';
import {NotificationStack, INotification} from '@vidazoo/ui/lib/components/notificationStack';
import IActivity from 'interfaces/IActivity';
import {AccountOnboardingStatus} from 'common/enums';
import * as theme from './theme.scss';
import {appUrlsService} from 'services';
import {SpreadPageLoader} from '@vidazoo/ui-framework';
import milkshakeUsers from 'containers/milkshakeUsers';
import {UserRole} from '@vidazoo/ui-framework/lib/common/enums';
import {Loader, PageMessage} from '@vidazoo/ui';
import IAccount from '../../interfaces/IAccount';

export interface IAppProps {
    userName?: string;
    userEmail?: string;
    userAvatarUrl?: string;
    userActivities?: IActivity[];
    hiddenAppHeader?: boolean;
    onSignOut?: () => void;
    notifications?: INotification[];
    removeNotification?: (notification: INotification, timeout?: boolean) => void;
    openMyProfile?: () => void;
    onboardingStatus?: AccountOnboardingStatus;
    isMilkshakeAdmin?: () => boolean;
    isMilkshakeUser?: () => boolean;
    defaultActivityName: string;
    isVidazooOrganization: boolean;
    currentAccount: IAccount;
    userSelfDestruct?: boolean;
    deleteAuthAccount?: () => void;
    userId?: string;
    userAccounts: IAccount[];
}

export interface IAppState {

}

export default class App extends React.Component<IAppProps, IAppState> {

    public static defaultProps: Partial<IAppProps> = {};

    constructor(props, context) {
        super(props, context);

    }

    private renderLoader() {
        return (
            <div className={theme.message}>
                <Loader size={2} gray className={theme.loader}/>
                <PageMessage
                    small
                    title="Loading Default Dashboard"
                    description="Please wait..."
                />
            </div>
        );
    }

    public onRedirect() {
        if (!this.props.defaultActivityName) {
            return this.renderLoader();
        }
        return <Redirect to={appUrlsService.defaultDashboard(this.props.defaultActivityName)}/>;
    }

    private renderSupervisor() {
        if (this.props.isVidazooOrganization) {
            return [
                <AuthRoute key="1" path={appUrlsService.adminLogsScheduleReports()} component={ScheduleReportsAdminLogs}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="2" path={appUrlsService.adminScheduleReportsAll()} component={ScheduleReportsAdminAll}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="3" path={appUrlsService.publishersReportingLogs()} component={ReportingLogs}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="4" path={appUrlsService.updateMetrics()} component={UpdateMetrics}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="5" path={appUrlsService.updateDimensions()} component={UpdateDimensions}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="6" path={appUrlsService.globalPresetsReports()} component={GlobalPresetsReports}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="7" path={appUrlsService.globalPresetReport()} component={GlobalPresetReport}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="8" path={appUrlsService.updatePointOfContact()} component={UpdatePointOfContact}
                           scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}/>,
                <AuthRoute key="9" path={appUrlsService.notificationCenter()} component={NotificationCenter}
                           scope={[UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN]}/>,
                <AuthRoute key="10" path={appUrlsService.notificationSettings()} component={NotificationSettings}
                           scope={[UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN]}/>,
            ];
        }
    }

    private renderThirdPartyPublisher() {
        if (this.props.currentAccount.thirdPartyPublishers) {
            return [
                <AuthRoute key="11" path={appUrlsService.connections()} component={Connections}/>,
                <AuthRoute key="12" path={appUrlsService.connection()} component={Connection}/>,
                <AuthRoute key="13" path={appUrlsService.domainsLists()} component={DomainsLists}/>,
                <AuthRoute key="14" path={appUrlsService.domainsList()} component={DomainList}/>,
                <AuthRoute key="15" path={appUrlsService.countryLists()} component={CountryLists}/>,
                <AuthRoute key="16" path={appUrlsService.countryList()} component={CountryList}/>
            ];
        }
    }

    public render(): JSX.Element {
        const {
            onboardingStatus,
            hiddenAppHeader,
            userName,
            userEmail,
            userAvatarUrl,
            userActivities,
            onSignOut,
            notifications,
            removeNotification,
            openMyProfile,
            isMilkshakeAdmin,
            isMilkshakeUser,
            defaultActivityName,
            currentAccount,
            isVidazooOrganization,
            userSelfDestruct,
            deleteAuthAccount,
            userId,
            userAccounts
        } = this.props;

        return (
            <div className={theme.app}>
                <AppHeader
                    hidden={hiddenAppHeader}
                    {...{
                        userName,
                        userEmail,
                        userAvatarUrl,
                        userActivities,
                        onSignOut,
                        openMyProfile,
                        isMilkshakeAdmin,
                        isMilkshakeUser,
                        defaultActivityName,
                        currentAccount,
                        isVidazooOrganization,
                        userSelfDestruct,
                        deleteAuthAccount,
                        userId,
                        userAccounts
                    }}
                />
                <NotificationStack
                    verticalGap={6}
                    horizontalGap={0}
                    notifications={notifications}
                    onClose={removeNotification}
                />
                <Profile
                    remove2fa
                    isVidazooOrganization={isVidazooOrganization}
                />
                <OnboardingPendingMessage onboardingStatus={onboardingStatus}/>
                <div className={theme.pageContainer}
                     style={{padding: location.pathname.includes('content-library') ? 0 : '2.4rem'}}>
                    <React.Suspense fallback={<SpreadPageLoader blue/>}>
                        <Switch>
                            <AuthRoute path={appUrlsService.siteList()} component={SiteList}/>
                            <AuthRoute path={appUrlsService.site()} component={Site}/>
                            <AuthRoute path={appUrlsService.widgets()} component={Widgets}/>
                            {currentAccount.exposeDemandPartners &&
                                <AuthRoute path={appUrlsService.demandPartners()} component={DemandPartners}/>}
                            {currentAccount.exposeVideos &&
                                <AuthRoute path={appUrlsService.videos()} component={Videos}/>}
                            <AuthRoute path={appUrlsService.submitSite()} component={SubmitSite}/>
                            <AuthRoute path={appUrlsService.accounts()} component={Accounts}
                                       scope={[UserRole.ADMIN, UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}
                                       noPermissionComponent={NoPermissionRoute}/>
                            <AuthRoute path={appUrlsService.account()} component={Account}
                                       scope={[UserRole.ADMIN, UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}
                                       noPermissionComponent={NoPermissionRoute}/>
                            <AuthRoute path={appUrlsService.dashboardActivity()} component={Dashboard}/>
                            <AuthRoute path={appUrlsService.reportingActivity()} component={Reporting}/>
                            <AuthRoute path={appUrlsService.scheduleReports()} component={ScheduleReports}/>
                            <AuthRoute path={appUrlsService.scheduleReport()} component={ScheduleReport}/>
                            <AuthRoute path={appUrlsService.onboardingIntro()} component={OnboardingIntro}/>
                            <AuthRoute path={appUrlsService.onboardingSubmitSiteList()}
                                       component={OnboardingSubmitSiteList}/>
                            <AuthRoute path={appUrlsService.onboardingDownloadAdsTxt()}
                                       component={OnboardingDemoWidget}/>
                            <AuthRoute path={appUrlsService.onboardingDemoWidget()} component={OnboardingDemoWidget}/>
                            <AuthRoute path={appUrlsService.onboardingCompanyLegal()}
                                       component={OnboardingCompanyLegal}/>
                            <AuthRoute path={appUrlsService.demoWidget()} component={DemoWidget}/>
                            <AuthRoute path={appUrlsService.downloadAdsTxt()} component={DownloadAdsTxt}/>
                            <AuthRoute path={appUrlsService.milkshakeUsers()} component={milkshakeUsers}
                                       scope={[UserRole.MILKSHAKE_ADMIN]} noPermissionComponent={NoPermissionRoute}/>
                            <AuthRoute path={appUrlsService.accountManagement()} component={AccountManagement}/>
                            <AuthRoute path={appUrlsService.testPages()} component={TestPages}
                                       scope={[UserRole.MILKSHAKE_ADMIN]} noPermissionComponent={NoPermissionRoute}/>

                            {isVidazooOrganization ? (
                                <AuthRoute path={appUrlsService.contentLibrary()} component={MediaCatalog}/>
                            ) : null}
                            {this.renderSupervisor()}
                            {this.renderThirdPartyPublisher()}
                            {this.onRedirect()}
                        </Switch>
                    </React.Suspense>
                </div>
            </div>
        );
    }
}
