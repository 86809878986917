import {action, computed, observable, runInAction, transaction} from "mobx";
import Papa from "papaparse";
import {guid} from "@vidazoo/ui-framework";
import {notificationsStore} from "./index";
import connectionsAPI from "../api/connectionsAPI";
import {exportToCsvService} from "../services";

export interface IAllowedField {
    name: string;
    displayName: string;
    type: "string" | "number" | "boolean";
    required: boolean;
    message?: string;
}

export const MultiCsvFields = [
    {name: "ID", key: "id"},
    {name: "Name", key: "name"}
];

export interface IConnectionCreateItem {
    id: string;
    confirm: boolean;
    name: string;
    widget: string;
    publisher: string;
    thirdPartyRevenueShare: number;
    iosThirdPartyRevenueShare: number;
    iosThirdPartyRevenueShareEnabled: boolean;
    iosPublisherRevenueShare: number;
    publisherRevenueShare: number;
}

export interface IConnectionMultiCreateResult {
    id: string;
    name: string;
    error: string;
}

export class ConnectionsMultiCreateStore {

    @observable public items: any[] = [];
    @observable public isLoadingCreateItems: boolean = false;
    @observable public isModalOpen: boolean = false;
    @observable public results: any[] = [];
    @observable public seenFields = observable.map<string, IAllowedField>();
    readonly allowedFields: IAllowedField[] = [
        {name: "name", displayName: "Name", type: "string", required: true},
        {name: "responseType", displayName: "Type", type: "string", required: true, message: "banner | video"},
        {
            name: "thirdPartyRevenueShare",
            displayName: "Third Party Revenue Share",
            type: "number",
            required: false,
            message: "0-1 (0.1 = 10%)"
        },
        {
            name: "iosThirdPartyRevenueShareEnabled",
            displayName: "IOS Third Party Revenue Share Enabled",
            type: "boolean",
            required: false
        },
        {
            name: "iosThirdPartyRevenueShare",
            displayName: "IOS Third Party Revenue Share",
            type: "number",
            message: "0-1 (0.1 = 10%)",
            required: false
        },
        {name: "floor", displayName: "Floor", type: "number", required: false},
        {name: "thirdPartySellerId", displayName: "Third Party Seller Id", type: "string", required: false}
    ];


    @action
    public reset() {
        this.items = observable.array([]);
        this.results = observable.array([]);
    }

    @action
    private async _parseCsvToObjects(csv: string): Promise<any[]> {

        const {errors, data} = Papa.parse(csv, {header: true, skipEmptyLines: true});

        if (errors.length) {
            throw new Error(errors[0].message);
        }

        if (data.length === 0) {
            throw new Error("no rows");
        }

        return data;
    }


    private _createItem = (item) => {
        const dto = {
            id: guid(),
            confirm: true,
        };

        this.allowedFields.forEach((field) => {
            if (item[field.name] === undefined || item[field.name] === null || item[field.name] === "") {
                if (field.required) {
                    throw new Error(`Required field ${field.name} is missing`);
                }
                return;
            }
            this.seenFields.set(field.name, field);
            switch (field.type) {
                case "string":
                    dto[field.name] = String(item[field.name]);
                    break;
                case "number":
                    dto[field.name] = Number(item[field.name]);
                    break;
                case "boolean":
                    dto[field.name] = Boolean(item[field.name]);
                    break;
                default:
                    throw new Error(`Unknown field type: ${field.type}`);
            }
        });
        return dto;
    }


    private _handleError = (e) => {
        notificationsStore.pushErrorNotification({
            success: false,
            title: "Error",
            timeout: 3000,
            text: e.message,
        });
        this.reset();
    }

    @action public setConfirm = (id: string) => {
        const found = this.items.find((item) => item.id === id);
        if (found) {
            found.confirm = !found.confirm;
        }
    }

    @action
    public async setItemsFromCsv(csv: string) {
        try {
            const items = await this._parseCsvToObjects(csv);
            runInAction(() => {
                this.items = items.map(this._createItem);
            });
        } catch (e) {
            this._handleError(e);
        }
    }

    @action
    public onDownloadCSV = () => {
        exportToCsvService.exportItems(this.results, MultiCsvFields, 'connections');
    };

    @action public submit = async () => {
        try {
            this.isLoadingCreateItems = true;
            const items = this.items.filter((item) => item.confirm);
            if (items.length === 0) {
                throw new Error("No items selected");
            }
            const res = await connectionsAPI.createMulti({items});
            if (res && res.data) {
                this._setResults(res.data);
            } else {
                throw new Error("No results");
            }
        } catch (e) {
            this._handleError(e);
        }
        runInAction(() => {
            this.isLoadingCreateItems = false;
        });

    }

    @action private _setResults = (results) => {
        this.results = results;
    }

    @action public toggleModal = () => {
        this.reset();
        this.isModalOpen = !this.isModalOpen;
    }

    @computed
    public get uiFields(): IAllowedField[] {
        return Array.from(this.seenFields.values());
    }

    @action public uploadCsv = (e) => {
        const files = e.target.files;

        if (!files || !files.length) {
            return;
        }

        const reader = new FileReader();
        reader.readAsText(files[0], "UTF-8");

        reader.onloadend = (event: any) => {
            const csv = event.target.result.replace(/\r\n|\r/g, "\n");
            this.setItemsFromCsv(csv);
        };
    };


}